const MSearchPage = () => {
    function redirectToEnglishVersion() {
        if (typeof window !== `undefined`) {
            window.location.replace("/products/msearch/en");
        }
    }
    redirectToEnglishVersion();
    return null;
};
export default MSearchPage;
